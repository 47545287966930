import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Draw from 'components/draw';
import { graphql } from 'gatsby';
import IOHeadline from 'components/io-headline';
import { AnimatedContainer } from 'components/header';
import { Intro, IntroText } from 'components/intro/intro.css';
import { InfoBox } from 'components/box/box.css';

const Index = ({ data }) => (
  <>
    <Layout home>
      <Draw />
      <AnimatedContainer>
        <IOHeadline>
          <Intro>
            <IntroText home as="h1">
              {data.homeJson.content.childMarkdownRemark.rawMarkdownBody}
            </IntroText>
          </Intro>
        </IOHeadline>
        <InfoBox>
          Scroll to change brush size and click 0-9 to change brush colors.
          Anyone can draw on my website and the internet can be a horrible
          place. Please dont be horrible.
        </InfoBox>
      </AnimatedContainer>
    </Layout>
  </>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
  }
`;
