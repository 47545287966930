import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  //background: #fff;

  #brush {
    position: fixed;
    width: 50px;
    height: 50px;
    user-select: none;
    z-index: 0;
    border-radius: 9999999px;
    cursor: crosshair;
    border: 1px solid #ffffff;
  }
`;
