/* eslint-disable */
import React, { Component, useEffect } from 'react';
import { Container } from './draw.css';

import { withFirebase } from '../firebase/index.js';
import getFirebase from '../firebase';
//import 'p5/lib/addons/p5.dom';
let P5Wrapper;

class Draw extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      p5ready: false,
      P5Wrapper: null,
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;
      //this.renderSketch();
      const firebase = this.props.firebase;
      if (typeof window !== 'undefined') {
        if (firebase) {
          this.setState({ p5ready: true });
        }
      }
    }
  };

  componentDidMount() {
    const P5Wrapper = import('react-p5-wrapper').then(
      ({ default: P5Wrapper }) => {
        require('p5/lib/addons/p5.dom');
        this.setState({ P5Wrapper: P5Wrapper });

        //P5Wrapper = this.state.P5Wrapper;
        this.firebaseInit();
      }
    );
  }

  sketch = canvas => {
    let newLines = [];
    let newLineCount = 1;
    let oldLines = [];
    let count = 0;
    let endOfTheLine = 1;
    let speed = 30;
    let history = 100;
    let pKey = 0;

    //if(!canvas) {return null;}
    const firebase = this.props.firebase;
    const database = this.props.firebase.database;
    const recentLines = firebase.lastLines();

    //database.ref('lines').set({}); //clear database
    const randomColors = Array.apply(null, Array(13)).map(function(
      item,
      index
    ) {
      return '#' + Math.floor(Math.random() * 16777215).toString(16);
    });

    recentLines.on('child_added', function(data) {
      //canvas.drawLine(data.val());
      //console.log("pLine",pLine);
      //console.log("newLine",data.key);
      if (pKey != data.key) {
        oldLines.push(data.val());
        //console.log("newLine!");
      } else {
        //console.log('thats your own line');
      }
    });

    const colors = [
      '#3B8686',
      '#FFFFFF',
      '#111111',
      '#BD1550',
      '#E97F02',
      '#F8CA00',
      '#8C9184',
      '#791E94',
      '#2EC4B6',
      '#D1B6E1',
      '#ED9282',
      '#44633F',
      '#AACD6E',
    ];

    let myStroke;

    let pLine;
    let myLine;
    let brush;
    canvas.setup = () => {
      let canvasContainer = canvas.createCanvas(
        typeof window !== 'undefined' && window.innerWidth,
        typeof window !== 'undefined' && window.innerHeight
      );
      canvasContainer.parent('background');
      canvas.rectMode(canvas.CENTER);
      canvas.ellipseMode(canvas.CENTER);
      brush = canvas.select('#brush');
      let initialColor = colors[Math.round(Math.random(colors.length) * 10)];

      myStroke = canvas.floor(canvas.random(10, 40));
      brush.style('opacity', 1);
      brush.style('width', myStroke + 'px');
      brush.style('height', myStroke + 'px');
      brush.style('background-color', initialColor);
      myLine = {
        style: myStroke,
        color: initialColor,
        points: [],
      };
    };

    canvas.draw = () => {
      brush.position(
        canvas.mouseX - myLine.style / 2,
        canvas.mouseY - myLine.style / 2
      );

      if (speed > 2) {
        speed -= 0.001;
      } else {
        speed = 2;
      }

      if (canvas.mouseIsPressed) {
        //brush.style('opacity', 0);
        canvas.stroke(myLine.color);
        canvas.strokeWeight(myLine.style);
        canvas.line(
          canvas.pmouseX,
          canvas.pmouseY,
          canvas.mouseX,
          canvas.mouseY
        );
        myLine.points.push({ x: canvas.mouseX, y: canvas.mouseY });
      }

      if (oldLines.length !== 0) {
        const thisLine = oldLines[0];

        endOfTheLine = thisLine.points.length - speed;

        count += canvas.drawSegment(thisLine, count, speed);

        if (count >= endOfTheLine) {
          count = 0;
          oldLines.shift();
        }
      }
    };

    canvas.drawLine = object => {
      const points = object.points;
      canvas.stroke(object.color);
      canvas.strokeWeight(object.style);
      for (let i = 1; i < points.length; i++) {
        canvas.line(points[i - 1].x, points[i - 1].y, points[i].x, points[i].y);
      }
    };

    canvas.drawSegment = (object, index, speed) => {
      const points = object.points;
      canvas.stroke(object.color);
      //canvas.stroke(colors[int(random(colors.length))]);
      //canvas.stroke(0);
      canvas.strokeWeight(object.style);
      //canvas.strokeWeight(1);
      let mySpeed = canvas.int(speed);

      if (points.length - 1 <= mySpeed) {
        mySpeed = canvas.int(points.length - 1);
      }

      for (let i = 0; i < mySpeed; i++) {
        canvas.line(
          points[index + i].x,
          points[index + i].y,
          points[index + 1 + i].x,
          points[index + 1 + i].y
        );
      }

      return mySpeed;
    };
    canvas.pushLine = object => {
      if (object.points.length != 0) {
        let pushedLine = database.ref('lines').push();
        pKey = pushedLine.key;

        pushedLine.set({
          style: object.style,
          color: object.color,
          points: object.points,
        });
      }

      myLine.points = [];
      //myLine.color = colors[0];
      //myLine.color = 0;
    };

    canvas.mouseReleased = () => {
      if (
        typeof window !== 'undefined' &&
        window.matchMedia('(max-width: 768px)').matches
      ) {
        /* The viewport is less than, or equal to, 700 pixels wide */
      } else {
        canvas.pushLine(myLine);
        brush.style('opacity', 0);
        /* The viewport is greater than 700 pixels wide */
      }
    };

    canvas.mouseWheel = event => {
      //println(event.delta);
      //pos += event.delta;
      //scrolling = true;
      myLine.style += event.delta / 100;
      //myStroke += event.delta/100;
      //myStroke = constrain(myStroke, 1, 500);
      myLine.style = canvas.constrain(myLine.style, 1, 500);
      brush.style('opacity', 1);
      brush.style('width', myLine.style + 'px');
      brush.style('height', myLine.style + 'px');
    };

    /*function windowResized() {
      resizeCanvas(windowWidth, windowHeight);
    }*/

    canvas.keyTyped = () => {
      // make brush smaller
      if (canvas.key === '-') {
        myLine.style -= 1;
        //myLine.style = constrain(myLine.style, 1, 500);
        brush.style('opacity', 1);
        brush.style('width', myLine.style + 'px');
        brush.style('height', myLine.style + 'px');
      }
      //make brush bigger
      if (canvas.key === '+') {
        myLine.style += 1;
        //myLine.style = constrain(myLine.style, 1, 500);
        brush.style('opacity', 1);
        brush.style('width', myLine.style + 'px');
        brush.style('height', myLine.style + 'px');
      }
      if (canvas.key < 10 && canvas.key >= 0) {
        myLine.color = colors[canvas.key];
        brush.style('background-color', myLine.color);
        brush.style('opacity', 1);
        // eslint-disable-next-line no-empty
      } else {
      }
    };
  };

  renderSketch = () => {
    const sketch2 = this.sketch;
    P5Wrapper = this.state.P5Wrapper;

    return <P5Wrapper sketch={sketch2} />;
  };

  componentDidUpdate() {
    this.firebaseInit();
  }

  initSketch = () => {
    this.setState({ loading: true });

    //console.log(this.props.firebase);
  };

  render() {
    return (
      <>
        <Container>
          {this.state.P5Wrapper && this._initFirebase
            ? this.renderSketch()
            : null}
          <div id="background" />
          <div id="brush" />
        </Container>
      </>
    );
  }
}

const DrawBase = withFirebase(Draw);
export default DrawBase;
